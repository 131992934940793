import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DemoState, initialState } from './DemoState';

export const demoSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setDemoMode: (state: DemoState, action: PayloadAction<boolean>) => {
            state.demoMode = action.payload;
            if(action.payload && localStorage.getItem('demo-replacements') != null) {
                state.demoReplacements = JSON.parse(localStorage.getItem('demo-replacements') ?? '[]');
            } else if(action.payload && localStorage.getItem('demo-replacements') == null) {
                localStorage.setItem('demo-replacements', JSON.stringify(state.demoReplacements));
            }
        }
    },
});

export const { setDemoMode } = demoSlice.actions;

export default demoSlice.reducer;
