import { useAppSelector } from "../../redux/app/hooks";

export const useDemo = () => {

    const { demoMode, demoReplacements } = useAppSelector(state => state.demo);

    const toFake = (content: string | null | undefined): string => {
        if (!demoMode || !content) return content ?? '';
        let fake = content;
        demoReplacements.forEach(replacement => {
            const regex = new RegExp(replacement.real, 'gi');
            fake = fake.replace(regex, replacement.fake);
        });
        return fake;
    }

    const toReal = (content: string | null | undefined): string => {
        if (!demoMode || !content) return content ?? '';
        let real = content;
        demoReplacements.forEach(replacement => {
            const regex = new RegExp(replacement.fake, 'gi');
            real = real.replace(regex, replacement.real);
        });
        return real;
    }

    return {
        toFake,
        toReal,
    };
}