import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Link,
    Spinner,
    Text,
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components';
import { customTokens } from '../../../styles';
import { FileIcon } from './FileIcon';
import { useChat } from '../../../libs/hooks/useChat';
import { useState } from 'react';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginTop: customTokens.spacingVerticalS,
        backgroundColor: customTokens.colorNeutralBackground3,
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
    },
    header: {
        '> button': {
            paddingLeft: '2px',
        },
    },
    headerText: {
        flexDirection: 'row',
        display: 'flex',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '100%',
    },
    downloadSpinner: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%',
    },
    panel: {
        paddingTop: customTokens.spacingVerticalXXS,
        paddingBottom: customTokens.spacingVerticalM,
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: customTokens.spacingVerticalS,
        marginBottom: customTokens.spacingVerticalS,
        flexWrap: 'wrap',
    },
    pillWrapper: {
        marginBottom: customTokens.spacingHorizontalS,
    },
    pill: {
        ...shorthands.borderRadius(0, customTokens.borderRadiusCircular, customTokens.borderRadiusCircular, 0),
        backgroundColor: customTokens.colorBrandBackground,
        lineHeight: customTokens.lineHeightBase100,
        ...shorthands.padding(customTokens.spacingVerticalXXS, customTokens.spacingHorizontalS, customTokens.spacingVerticalXXS, customTokens.spacingHorizontalXS),
        marginRight: customTokens.spacingHorizontalS,
        fontSize: customTokens.fontSizeBase200,
    },
    pillForDocumentUpload: {
        backgroundColor: `color-mix(in srgb, ${customTokens.ekfbBlue} 70%, transparent)`,
    },
    pillWithoutCitation: {
        ...shorthands.borderRadius(customTokens.borderRadiusCircular),
    },
    citationNumberInPill: {
        ...shorthands.padding(customTokens.spacingVerticalXXS, customTokens.spacingHorizontalS),
        color: customTokens.colorBrandBackgroundInverted,
        backgroundColor: customTokens.colorBrandBackgroundPressed,
        fontSize: customTokens.fontSizeBase200,
        cursor: 'default',
        ...shorthands.borderRadius(customTokens.borderRadiusCircular, 0, 0, customTokens.borderRadiusCircular),
    },
    citationNumberInPillForDocumentUpload: {
        backgroundColor: customTokens.ekfbBlue,
    },
    link: {
        color: customTokens.colorBrandBackgroundInverted,
        ':hover': {
            color: customTokens.colorBrandBackgroundInverted,
        },
    },
    noUrl: {
        cursor: 'default',
        textDecorationLine: 'none',
        ':hover': {
            textDecorationLine: 'none',
        },
        ':active': {
            textDecorationLine: 'none',
            color: customTokens.colorBrandBackgroundInverted,
        },
    },
});

interface ICitationsProps {
    citations?: Array<{ ref: string; index: number }>;
    contextCitationsIndexes?: number[];
    isAttachments?: boolean;
    source?: string;
}

export const Citations: React.FC<ICitationsProps> = ({ citations, contextCitationsIndexes, isAttachments, source }) => {
    const classes = useClasses();
    const chat = useChat();

    // state for downloading attachments
    const [downloading, setDownloading] = useState(false);
    const [downloadingFile, setDownloadingFile] = useState('');

    // if there are no citations, don't render anything
    if (!citations || citations.length === 0) {
        return null;
    }

    const downloadAttachment = async (attachmentPath: string, source: string) => {
        // get filename from path
        const fileName = attachmentPath.split('/').pop();

        // get file extension
        const fileExtension = fileName?.split('.').pop();

        // set downloading file name
        setDownloadingFile(fileName ?? '');
        setDownloading(true);

        // The attachment path is a URL, we need to remove the domain name and the first folder
        // to get the relative path to the file
        const relativePath = attachmentPath.split('/').slice(4).join('/');

        const blob = await chat.downloadAttachment(relativePath, source);

        if (blob instanceof Blob) {
            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element and click it to initiate download
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName ?? `attachment.${fileExtension}`;
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
        }

        setDownloadingFile('');
        setDownloading(false);
    };

    // citations is an array of strings which are links to the sources of the information
    // we need to get the domain name of each link and display it in a list
    // for example, if the citation is https://www.cdc.gov/coronavirus/2019-ncov/vaccines/fully-vaccinated.html
    // we want to display "cdc.gov" in the list with link as the href

    const citationList: JSX.Element[] = [];
    citations.forEach((citation, index) => {
        const isUploadedDocument = citation.ref.startsWith('Document: ');

        if (isUploadedDocument) {
            const documentName = citation.ref.replace('Document: ', '');
            const documentNameSliced = documentName.slice(0, 23);

            citationList.push(
                <>
                    <span className={mergeClasses(classes.citationNumberInPill)}>
                        {citation.index + 1}
                    </span>
                    <span className={mergeClasses(classes.pill)}>
                        <FileIcon fileNameOrURL={documentName} />
                        <Link className={mergeClasses(classes.link, classes.noUrl)} key={index} title={documentName}>
                            {contextCitationsIndexes?.includes(index)
                                ? `${index + 1}. ${documentNameSliced}`
                                : documentNameSliced}
                            {/* If documentNameSliced is not same as documentName then display ... */}
                            {documentNameSliced !== documentName && '...'}
                        </Link>
                    </span>
                </>,
            );
        } else {
            let url;
            let isValidUrl = true;
            let documentSource = '';

            const isSourceDocument = citation.ref.includes(';#');

            if (isSourceDocument) {
                documentSource = citation.ref.split(';#')[0];
                citation.ref = citation.ref.split(';#')[1];
            }

            try {
                url = new URL(citation.ref);
            } catch (e) {
                isValidUrl = false;
            }

            if (isValidUrl && url) {
                const domain = url.hostname.replace('www.', '');

                if (domain.includes('sharepoint.com') || domain.includes('blob.core.windows.net')) {
                    let documentName = url.pathname.split('/').pop();
                    if (url.searchParams.has('file')) {
                        documentName = url.searchParams.get('file') as string;
                    }

                    if (!documentName) {
                        return;
                    }

                    const decodedDocumentName = decodeURIComponent(documentName);
                    const documentNameSliced = decodedDocumentName.slice(0, 23);

                    citationList.push(
                        <>
                            <span className={mergeClasses(classes.citationNumberInPill)}>
                                {citation.index + 1}
                            </span>
                            <span className={classes.pill}>
                                <Link
                                    className={classes.link}
                                    key={index}
                                    title={decodedDocumentName}
                                    // If isAttachments is true, we want to download the attachment
                                    target={isAttachments ? undefined : '_blank'}
                                    href={isAttachments ? undefined : citation.ref}
                                    onClick={
                                        isAttachments
                                            ? () => downloadAttachment(citation.ref, (source ?? documentSource).toLocaleLowerCase())
                                            : undefined
                                    }
                                >
                                    <FileIcon fileNameOrURL={citation.ref} />
                                    {contextCitationsIndexes?.includes(index)
                                        ? `${index + 1}. ${documentNameSliced}`
                                        : documentNameSliced}
                                    {documentNameSliced !== documentName && '...'}
                                </Link>
                            </span>
                        </>,
                    );
                } else {
                    citationList.push(
                        <>
                            <span className={mergeClasses(classes.citationNumberInPill)}>{citation.index + 1}</span>
                            <span className={mergeClasses(classes.pill)}>
                                <Link className={classes.link} href={citation.ref} target="_blank" key={index}>
                                    {domain}
                                </Link>
                            </span>
                        </>,
                    );
                }
            } else {
                const fileName = citation.ref;
                const fileNameSliced = citation.ref.slice(0, 23);

                citationList.push(
                    <span className={mergeClasses(classes.pill, classes.pillWithoutCitation)}>
                        <Link className={mergeClasses(classes.link, classes.noUrl)} key={index} title={fileName}>
                            <FileIcon fileNameOrURL={citation.ref} />
                            {contextCitationsIndexes?.includes(index)
                                ? `${index + 1}. ${fileNameSliced}`
                                : fileNameSliced}
                            {fileNameSliced !== fileName && '...'}
                        </Link>
                    </span>,
                );
            }
        }
    });

    return (
        <div className={classes.root}>
            <Accordion collapsible>
                <AccordionItem value="">
                    <AccordionHeader className={classes.header}>
                        <Text size={200} weight="bold" className={classes.headerText}>
                            {isAttachments ? <>Attachments*&nbsp;</> : 'Response citations*'}
                            {downloading && (
                                <div className={classes.downloadSpinner}>
                                    <Spinner size="extra-tiny" label={`Downloading ${downloadingFile}`} />
                                </div>
                            )}
                        </Text>
                    </AccordionHeader>
                    <AccordionPanel className={classes.panel}>
                        {isAttachments ? (
                            <Text size={200}>Here is a list of attachments related to the response above:</Text>
                        ) : (
                            <Text size={200}>Here is a list of citations used to generate the response above:</Text>
                        )}
                        <div className={classes.linksContainer}>
                            {citationList.map((citation, index) => (
                                <div key={index} className={classes.pillWrapper}>
                                    {citation}
                                </div>
                            ))}
                        </div>
                        {isAttachments ? (
                            <Text size={200} italic>
                                * This response was generated using a combination of archive sources and Azure ChatGPT.
                            </Text>
                        ) : (
                            <Text size={200} italic>
                                * This response was generated using a combination of internet sources and Azure ChatGPT.
                            </Text>
                        )}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
};
