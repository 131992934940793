export interface IDataTypeBase {
    name: string;
    background: string;
    text: string;
}

export const dataTypeStyles: IDataTypeBase[] = [
    {
        name: 'Action',
        background: '#FF6347',
        text: '#F5F5F5',
    },
    {
        name: 'Audit',
        background: '#6A5ACD',
        text: '#F5F5F5',
    },
    {
        name: 'Compensation Event',
        background: '#0078D4',
        text: '#FFFFFF',
    },
    {
        name: 'Document',
        background: '#483D8B',
        text: '#F5F5F5',
    },
    {
        name: 'Defect',
        background: '#498205',
        text: '#FFFFFF',
    },
    {
        name: 'Early Warning Risk',
        background: '#CAEEE9',
        text: '#007564',
    },
    {
        name: 'General Communication',
        background: '#D8D8EE',
        text: '#373277',
    },
    {
        name: 'Incident',
        background: '#B22222',
        text: '#F5F5F5',
    },
    {
        name: 'Instruction',
        background: '#9E6C00',
        text: '#FFFFFF',
    },
    {
        name: 'Operative',
        background: '#008080',
        text: '#F5F5F5',
    },
    {
        name: 'Payment',
        background: '#80C6FF',
        text: '#004E8C',
    },
    {
        name: 'Programme',
        background: '#D4E7F6',
        text: '#0068B8',
    },
    {
        name: 'Qualification',
        background: '#800000',
        text: '#F5F5F5',
    },
    {
        name: 'Quotation',
        background: '#E5D2E3',
        text: '#86417B',
    },
    {
        name: 'Report Card',
        background: '#FFA500',
        text: '#F5F5F5',
    },
    {
        name: 'Training',
        background: '#008000',
        text: '#F5F5F5',
    },
    {
        name: 'Technical Query',
        background: '#373277',
        text: '#FFFFFF',
    }
];