import { useMsal } from '@azure/msal-react';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { AuthHelper } from '../auth/AuthHelper';
import { AppConfigService, IAppConfigValue } from '../services/AppConfigService';
import { IArchiveSourceAvailability } from '../models/ArchiveSourceAvailability';

export interface IAppConfigResult<T> {
    Data?: T;
    Success: boolean;
    Message?: string;
}

interface JwtRolesPayload extends JwtPayload {
    roles?: string[]
}

export const useAppConfig = () => {
    const { instance, inProgress } = useMsal();

    const appConfigService = new AppConfigService(process.env.REACT_APP_BACKEND_URI as string);

    const getConfigSingleValue = async (key: string, user: string): Promise<IAppConfigResult<IAppConfigValue>> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        try {
            const value = await appConfigService.getConfigSingleValueAsync(key, accessToken, { user, useBrowserCache: false });
            return { Data: value, Success: true };
        } catch (e: any) {
            const errorMessage = `Error getting config value ${key}. Details: ${getErrorDetails(e)}`;
            return { Success: false, Message: errorMessage };
        }
    };

    const getConfigMultipleValues = async (key: string, user: string): Promise<IAppConfigResult<IAppConfigValue[]>> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        try {
            const values = await appConfigService.getConfigMultipleValues(key, accessToken, { user, useBrowserCache: false });
            return { Data: values, Success: true };
        } catch (e: any) {
            const errorMessage = `Error getting config values ${key}. Details: ${getErrorDetails(e)}`;
            return { Success: false, Message: errorMessage };
        }
    };

    const getArchiveConfig = async (): Promise<IAppConfigResult<IArchiveSourceAvailability>> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const allPlatforms = await appConfigService.getArchiveConfig(accessToken);
            const allowedPlatforms: IArchiveSourceAvailability = { loaded: true, Sources: [] };
            const decodedToken = jwtDecode<JwtRolesPayload>(accessToken);
            if (decodedToken.roles) {
                decodedToken.roles.sort();
                for (const [_index, roleName] of decodedToken.roles.entries()) {
                    const platformIndex = allPlatforms.Sources.findIndex(p => p.PlatformName === roleName.replace(".Read", ""));
                    if (platformIndex !== -1) {
                        allowedPlatforms.Sources.push(allPlatforms.Sources[platformIndex]);
                    }
                }
            }
            return { Data: allowedPlatforms, Success: true };
        } catch (e: any) {
            const errorMessage = `Error getting archive config values. Details: ${getErrorDetails(e)}`;
            return { Success: false, Message: errorMessage };
        }
    };


    return {
        getConfigSingleValue,
        getConfigMultipleValues,
        getArchiveConfig
    };
};

function getErrorDetails(e: any) {
    return e instanceof Error ? e.message : String(e);
}
