import {
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    makeStyles,
    shorthands,
    Spinner,
    tokens,
} from '@fluentui/react-components';
import { customTokens } from '../../../styles';
import { ArrowDownload16Filled, CheckmarkCircle48Filled } from '@fluentui/react-icons';
import { useChat } from '../../../libs/hooks/useChat';
import React from 'react';
import { IChatExtendedDetails } from '../../../libs/models/ChatMessage';
import * as XLSX from 'xlsx';
import { useGraph } from '../../../libs/hooks';

interface IChatDownloadReportProps {
    extendedDetails?: IChatExtendedDetails;
}

export interface ExtendedDetailsResponse {
    jsonStringValue: string;
}

export interface IArchiveItem {
    Platform: string;
    Type: string;
}

const useClasses = makeStyles({
    extendedDetailsButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: customTokens.spacingVerticalS,
        paddingTop: customTokens.spacingVerticalS,
        paddingBottom: customTokens.spacingVerticalS,
        backgroundColor: customTokens.colorNeutralStencil1,
        ...shorthands.borderRadius(customTokens.borderRadiusSmall),
    },
    extendedDetailsButton: {
        marginRight: customTokens.spacingHorizontalL,
    },
    textCopiedDialog: {
        textAlign: 'center',
        maxWidth: '200px',
    },
    checkmarkIcon: {
        color: tokens.colorBrandForeground2,
    },
    downloadReportSpinner: {
        marginRight: customTokens.spacingHorizontalL,
    },
});

export const ChatDownloadReport: React.FC<IChatDownloadReportProps> = ({ extendedDetails }) => {
    const classes = useClasses();

    const chat = useChat();
    const msGraph = useGraph();

    const [downloadingReport, setDownloadingReport] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(false);

    const dataURItoBlob = (byteString: string, mimeString: string) => {
        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        const ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    };

    React.useEffect(() => {
        let timer: NodeJS.Timeout;

        if (showNotification) {
            timer = setTimeout(() => {
                setShowNotification(false);
            }, 2000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [showNotification]);

    const downloadExtendedDetailsAsString = async (details?: IChatExtendedDetails) => {
        setDownloadingReport(true);

        const extendedDetailsResponse = await chat.downloadExtendedDetailsAsString(details);

        if (extendedDetailsResponse?.jsonStringValue) {
            const extendedDetailsJson = extendedDetailsResponse.jsonStringValue
                .replaceAll('\\"', '&qoute;')
                .replaceAll('\\', '\\\\')
                .replace(/\n|\r/g, " ");
                
            const extendedDetailsRaw = JSON.parse(extendedDetailsJson) as IArchiveItem[][];

            const getPlatformsTypes = [
                ...new Set(extendedDetailsRaw.map((item: IArchiveItem[]) => `${item[0].Platform}-${item[0].Type}`)),
            ];

            const workbook = XLSX.utils.book_new();

            for (const platformType of getPlatformsTypes) {
                const extendedDetailsRawFiltered = extendedDetailsRaw.filter(
                    (e: IArchiveItem[]) =>
                        e[0].Platform === platformType.split('-')[0] && e[0].Type === platformType.split('-')[1],
                );
                const extendedDetailsJsonFiltered = JSON.parse(JSON.stringify(extendedDetailsRawFiltered)) as Array<
                    Array<Record<string, string>>
                >;
                const worksheet = XLSX.utils.json_to_sheet(extendedDetailsJsonFiltered.map((e) => e[0]));
                XLSX.utils.book_append_sheet(workbook, worksheet, platformType);
            }

            const excelFileName = `ArchiveReport_${new Date().toISOString().replace(/-|\.|:|Z/g, '')}.xlsx`;
            const folderName = 'Thomas the AI Engine - Archive Report';

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const fileStream = XLSX.write(workbook, { type: 'binary', bookType: 'xlsx' });
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const blob = dataURItoBlob(fileStream, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void msGraph
                .uploadFileToOneDrive(excelFileName, folderName, blob)
                .then((uploadResult?: { webUrl?: string }) => {
                    if (uploadResult?.webUrl) {
                        window.open(uploadResult.webUrl, '_blank');
                    }

                    setDownloadingReport(false);
                    setShowNotification(true);
                });
        }
    };

    return (
        <div className={classes.extendedDetailsButtonContainer}>
            {downloadingReport && <Spinner size="tiny" label={`Generating report...`} className={classes.downloadReportSpinner} />}
            <Button
                className={classes.extendedDetailsButton}
                appearance="secondary"
                disabled={downloadingReport}
                icon={<ArrowDownload16Filled />}
                // eslint-disable-next-line  @typescript-eslint/no-misused-promises, @typescript-eslint/no-non-null-assertion
                onClick={() => downloadExtendedDetailsAsString(extendedDetails)}
            >
                Download items export
            </Button>
            <Dialog
                open={showNotification}
                onOpenChange={(_event, data) => {
                    setShowNotification(data.open);
                }}
            >
                <DialogSurface className={classes.textCopiedDialog}>
                    <DialogBody>
                        <DialogContent>
                            <CheckmarkCircle48Filled className={classes.checkmarkIcon} />
                            <p>Report downloaded</p>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
};
