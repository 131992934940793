/// Information about token usage used to generate bot response.
export type TokenUsage = Record<string, number | undefined>;

export type TokenUsageView = Record<string, TokenUsageViewDetails>;

export interface TokenUsageViewDetails {
    usageCount: number;
    legendLabel: string;
    color: string;
}

export interface FunctionDetails {
    usageCount: number;
    legendLabel: string;
    color?: string;
}

export const TokenUsageFunctionNameMap: Record<string, string> = {
    systemPrompt: 'System Prompt',
    systemArchiveDescription: 'Archive Prompt',
    archiveSnippets: 'Archive Snippets',
    documentPrompt: 'Document Prompt',
    documentSnippets: 'Document Snippets',
    webSnippets: 'Web Snippets',
    systemPromptGuard: 'System Prompt Guard',
    chatHistory: 'Chat History',
    userAsk: 'User Ask',
    responseCompletion: 'GPT Response',
};
