import type { DropdownProps } from '@fluentui/react-components';
import { Dropdown, makeStyles, Option, shorthands, useId } from '@fluentui/react-components';
import {
    BotSparkle24Regular,
    Cloud24Regular,
    CloudArchive24Regular,
    CloudCheckmark24Regular,
    People24Regular,
} from '@fluentui/react-icons';
import * as React from 'react';
import { useChat } from '../../../libs/hooks';
import { IAppConfigResult, useAppConfig } from '../../../libs/hooks/useAppConfig';
import { AlertType } from '../../../libs/models/AlertType';
import { IArchiveSourceAvailability } from '../../../libs/models/ArchiveSourceAvailability';
import { IArchiveSources } from '../../../libs/models/ArchiveSources';
import { IAppConfigValue } from '../../../libs/services/AppConfigService';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { addAlert, setArchiveSourceAvailability } from '../../../redux/features/app/appSlice';
import { ChatScope } from '../../../redux/features/conversations/ChatState';
import { editConversationScope } from '../../../redux/features/conversations/conversationsSlice';
import { Breakpoints } from '../../../styles';
import { getScopeProperty, ScopeInfoDialog, ScopeOption } from '../scope-dialog/ScopeInfoDialog';

interface IEditChatScopeProps extends Partial<DropdownProps> {
    chatId: string;
    chatScope: ChatScope;
    hiddenChat: boolean;
}

const useStyles = makeStyles({
    root: {
        display: 'grid',
        justifyItems: 'start',
        ...shorthands.gap('20px'),
        maxWidth: '400px',
    },
    field: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'start',
        ...shorthands.gap('2px'),
        ...Breakpoints.medium({
            width: '100%',
        }),
    },
    optionLabel: {
        marginRight: '10px',
        display: 'block',
        '@media screen and (max-width: 897px)': { display: 'none' },
    },
    chatScopeDropdown: {
        ...Breakpoints.medium({
            width: '100%',
            minWidth: '0',
        }),
        '& button': {
            ...Breakpoints.medium({
                fontSize: '12px',
            }),
        },
    },
});

export const scopeOptionsDefault: ScopeOption = {
    [ChatScope.SharePoint]: {
        name: 'SharePoint',
        icon: () => <People24Regular />,
    },
    [ChatScope.Web]: {
        name: 'Web',
        icon: () => <Cloud24Regular />,
    },
    [ChatScope.RecommendedSites]: {
        name: 'RecommendedSites',
        icon: () => <CloudCheckmark24Regular />,
    },
    [ChatScope.None]: {
        name: 'None',
        icon: () => <BotSparkle24Regular />,
    },
    [ChatScope.Archive]: {
        name: 'Archive',
        icon: () => <CloudArchive24Regular />,
    },
};

export const EditChatScope: React.FC<IEditChatScopeProps> = (props) => {
    const [scopeOptions, setScopeOptions] = React.useState<ScopeOption>();
    const initialized = React.useRef(false);
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chat = useChat();
    const comboId = useId('combo-controlled');
    const styles = useStyles();

    const { activeUserInfo, archiveSourceAvailability } = useAppSelector((state: RootState) => state.app);
    const emailAddress = activeUserInfo?.email ?? '';
    const user = `${emailAddress}`;

    const appConfig = useAppConfig();

    const getPromptGuidanceContent = async () => {
        const response: IAppConfigResult<IAppConfigValue[]> = await appConfig.getConfigMultipleValues(
            'Scope Guidance',
            user,
        );

        if (response.Success) {
            const updatedScopes = Object.keys(scopeOptionsDefault).map((scopeKey: string) => {
                const scope = parseInt(scopeKey) as ChatScope;
                const config = response.Data?.find((d) => d.Category === scopeOptionsDefault[scope].name);
                const scopeTitle = config?.CategoryTitle ?? '';
                const scopeDescription = config?.Value ?? '';
                return { ...scopeOptionsDefault[scope], title: scopeTitle, description: scopeDescription };
            });

            setScopeOptions(updatedScopes.filter((f) => f.title));
        }
    };

    React.useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            const getArchiveConfig = async () => {
                const response: IAppConfigResult<IArchiveSourceAvailability> = await appConfig.getArchiveConfig();
                if (response.Success) {
                    dispatch(
                        setArchiveSourceAvailability({
                            loaded: true,
                            Sources: response.Data?.Sources ?? ([] as IArchiveSources[]),
                        }),
                    );
                }
            };
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            getPromptGuidanceContent();
            if (!archiveSourceAvailability?.loaded) {
                getArchiveConfig().catch((e: any) => {
                    const errorMessage = `Unable to get Archive Config. Details: ${
                        e instanceof Error ? e.message : String(e)
                    }`;
                    dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const checkScope = async () => {
            if (archiveSourceAvailability?.loaded && archiveSourceAvailability.Sources.length === 0) {
                const chatState = conversations[selectedId];
                if (chatState.chatScope === ChatScope.Archive) {
                    await chat
                        .editChat(
                            chatState.id,
                            chatState.title,
                            chatState.memoryBalance,
                            ChatScope.None,
                        )
                        .then(() => {
                            localStorage.setItem('lastSelectedChatScope', ChatScope.None.toString());
                            dispatch(editConversationScope({ id: selectedId, newScope: ChatScope.None }));
                        })
                        .catch((e: any) => {
                            const errorMessage = `Unable to change Chat Scope. Details: ${
                                e instanceof Error ? e.message : String(e)
                            }`;
                            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
                        });
                }
            }
        };

        checkScope().catch((e: any) => {
            const errorMessage = `Unable to change Chat Scope. Details: ${e instanceof Error ? e.message : String(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        });
    }, [archiveSourceAvailability, selectedId, chat, conversations, dispatch]);

    const onOptionSelect: (typeof props)['onOptionSelect'] = (_ev, data) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access

        if (data.optionValue) {
            onScopeChange(parseInt(data.optionValue) as ChatScope).catch((e: any) => {
                const errorMessage = `Unable to change chat scope. Details: ${
                    e instanceof Error ? e.message : String(e)
                }`;
                dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            });
        }
    };

    const onScopeChange = async (newScope: ChatScope) => {
        const chatState = conversations[selectedId];

        const prevState = chatState.chatScope;
        if (newScope == ChatScope.Archive && prevState !== ChatScope.Archive) {
            if (!archiveSourceAvailability?.loaded) {
                const response: IAppConfigResult<IArchiveSourceAvailability> = await appConfig.getArchiveConfig();
                if (response.Success) {
                    dispatch(
                        setArchiveSourceAvailability({
                            loaded: true,
                            Sources: response.Data?.Sources ?? ([] as IArchiveSources[]),
                        }),
                    );
                }
            }
        }

        await chat
            .editChat(chatState.id, chatState.title, chatState.memoryBalance, newScope)
            .then(() => {
                localStorage.setItem('lastSelectedChatScope', newScope.toString());
                dispatch(editConversationScope({ id: props.chatId, newScope }));
            });
    };

    const getScopeOptions = () => {
        return (
            scopeOptions &&
            Object.keys(scopeOptions).map((key) => {
                const scope = parseInt(key) as ChatScope;
                const title = getScopeProperty(scopeOptions, scope, 'title') as string;
                return title &&
                    !(
                        scope === ChatScope.Archive &&
                        archiveSourceAvailability &&
                        archiveSourceAvailability.Sources.length === 0
                    ) ? (
                    <Option key={scope} value={scope.toString()}>
                        {getScopeProperty(scopeOptions, scope, 'title') as string}
                    </Option>
                ) : null;
            })
        );
    };

    return scopeOptions ? (
        <>
            <div className={styles.field}>
                <ScopeInfoDialog scopeOptions={scopeOptions} />
                <label htmlFor={`${comboId}-controlled`} className={styles.optionLabel}>
                    Choose a source:
                </label>
                <Dropdown
                    id={`${comboId}-controlled`}
                    value={getScopeProperty(scopeOptions, props.chatScope, 'title') as string}
                    selectedOptions={[props.chatScope.toString()]}
                    onOptionSelect={onOptionSelect}
                    disabled={props.hiddenChat}
                    className={styles.chatScopeDropdown}
                >
                    {getScopeOptions()}
                </Dropdown>
            </div>
        </>
    ) : null;
};
